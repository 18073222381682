import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Refund Policy" />
    <div className="contentholder-central">
    <p>Policy coming soon.</p>
    </div>
  </Layout>
)

export default IndexPage
